<template>
  <div class="header py-0 shadow bg-white mt-3">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row py-3">
          <nav aria-label="breadcrumb" class="ml-2">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item">
                <a href="#"><i class="fas fa-link"></i></a>
              </li>
              <li class="breadcrumb-item"><a href="#"></a></li>
              List of Fields
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-2">
    <button
      class="mb-2 btn btn-default btn-sm"
      @click="$router.push({ name: 'New Field'})"
    >
      New
    </button>
    <div class="card shadow w-100 p-4">
      <loader v-if="!data_loaded" />
      <data-table
        v-if="data_loaded"
        id="fields_list"
        :options="dtb_options.extra_options"
        :actions="dtb_options.actions"
        :data="dtb_options.data"
        :columns="dtb_options.columns"
        :on_click="dtb_on_click"
      />
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/Datatable";
import IndependentLoader from "../../components/IndependentDefaultLoader";
import {
  getDocs,
  collection,
  doc,
  deleteDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { alert } from "../../utils/alertUtils";
export default {
  name: "Fields",
  components: {
    "data-table": DataTable,
    loader: IndependentLoader,
  },
  data() {
    return {
      th: this,
      data_loaded: false,
      fields: [],
      //   user_profile: this.$store.getters.getUserProfile,
      dtb_options: {
        data: [],
        actions: [
          {
            btnText: "Delete",
            btnClass: "btn btn-white text-danger btn-sm",
            iconClass: "fas fa-trash",
            btnFn: (uuid) => {
              this.delete_collection(uuid);
            },
            btnParamName: "uuid",
          },
          {
            btnText: "Update",
            btnClass: "btn btn-white text-[#154462] btn-sm",
            iconClass: "fas fa-edit",
            btnFn: (uuid) => {
              this.$router.push({
                name: "Field Update",
                params: { uuid },
              });
            },
            btnParamName: "uuid",
          },
          
        ],
        columns: [
          
         
          {
            title: "name",
            data: "named",
          },
          {
            title: "type",
            data: "type",
          },
          //   { title: "Position", data: "position" },
        ],
        extra_options: {},
      },
    };
  },
  methods: {
    dtb_on_click: function(row) {
      var uuid = row.uuid;
      console.log(uuid);
      //  this.$router.push({ name: "Accomodation Details", params: { uuid } });
    },
    // delete_collection: function(uuid) {
    //   alert.promptDelete(this.implement_delete_collection(uuid));
    // },

    delete_collection: function(uuid) {
      const ref = doc(db, "fields", uuid);
      deleteDoc(ref)
        .then(() => {
          this.dtb_options.data = [];
          this.fetch_fields();
          alert.success_center("You have successfully deleted Field");
        })
        .catch(() => {
          alert.error("Error occured in deleting Field");
        });
    },

    fetch_fields: function() {
      // fetch fields
      getDocs(collection(db, "fields"))
        .then((snapShots) => {
          snapShots.forEach((document) => {
            let fields = document.data();
            fields.uuid = document.id;
            this.dtb_options.data.push(fields);
          });
        })
        .then(() => {
          this.data_loaded = true;
        })
        .catch((e) => {
          console.log(`an erro occured ${e}`);
          this.data_loaded = false;
        });
    },

    testCreateSubCollection: function() {
      /**
       * this function is for testing adding new document in a sub collection
       * means it is a collection in a document withing a parent collection
       *
       * we have to get the subCollection reference from parent collection name
       * and it's child docId.
       */

      const subCollectionRef = collection(db, "services", "mtn", "services");

      addDoc(subCollectionRef, { name: "test subcollection fields" })
        .then(() => {
          console.log("success");
          alert.success_center("success");
        })
        .catch((e) => {
          console.log(e);
          alert.error(e);
        });
    },
  },
  created() {
    this.fetch_fields();
  },
};
</script>

<style></style>
